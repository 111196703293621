





























































































import Component, { mixins } from 'vue-class-component';
import VueContextLoading from '@/components/VueContextLoading';
import MenuModel from '@/models/MenuModel';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import IllustrationType from '@/utils/enums/IllustrationType';
import MessagePage from '@/views/guest/MessagePage.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import { Getter } from 'vuex-class';
import CommunityFeature from '@/models/graphql/CommunityFeature';

@Component({
  components: {
    MessagePage,
    FontAwesomeComponent,
  },
  beforeRouteEnter(from, to, next) {
    next((vm) => {
      if (!(vm.$store.getters.featureByKey(FeatureKeys.COMMUNITY_USER_PROFILE_SETTINGS)
        && vm.$store.getters.featureByKey(FeatureKeys.COMMUNITY_USER_PROFILE_SETTINGS).enabled)) {
        vm.$router.replace({ name: 'not-found' });
      }
    });
  },
})
export default class MainSettingsPage extends mixins(BreakpointWrapper, VueContextLoading) {
  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  private IllustrationType = IllustrationType;

  private fixed = false;

  private emailChanged = false;

  private newEmail = '';

  private get tabsMenuList(): MenuModel[] {
    const menuList = [
      {
        uid: '1',
        name: 'settings-account',
        title: 'settings-page.menu.account',
        icon: 'far fa-user-circle',
      },
      {
        uid: '2',
        name: 'settings-site-preferences',
        title: 'settings-page.menu.site-preferences',
        icon: 'far fa-display',
      },
      {
        uid: '3',
        name: 'settings-notifications',
        title: 'settings-page.menu.notifications',
        icon: 'far fa-bell',
      },
      {
        uid: '4',
        name: 'settings-security',
        title: 'settings-page.menu.privacy',
        icon: 'far fa-lock',
      },
    ];
    return menuList.filter((menu) => {
      switch (menu.name) {
        case 'settings-security':
          return (this.featureByKey(FeatureKeys.COMMUNITY_ENABLE_PROFILE_VISIBILITY)
              && this.featureByKey(FeatureKeys.COMMUNITY_ENABLE_PROFILE_VISIBILITY).enabled)
            || (this.featureByKey(FeatureKeys.COMMUNITY_ENABLE_BLOCKED_USERS)
              && this.featureByKey(FeatureKeys.COMMUNITY_ENABLE_BLOCKED_USERS).enabled);
        case 'settings-notifications':
          return (this.featureByKey(FeatureKeys.COMMUNITY_ENABLE_COMPANY_INVITATION)
              && this.featureByKey(FeatureKeys.COMMUNITY_ENABLE_COMPANY_INVITATION).enabled)
            || (this.featureByKey(FeatureKeys.COMMUNITY_ENABLE_COMPANY_MEETING)
              && this.featureByKey(FeatureKeys.COMMUNITY_ENABLE_COMPANY_MEETING).enabled)
            || (this.featureByKey(FeatureKeys.COMMUNITY_CONNECTIONS_FEATURE)
              && this.featureByKey(FeatureKeys.COMMUNITY_CONNECTIONS_FEATURE).enabled);
        default:
          return true;
      }
    });
  }

  created(): void {
    window.addEventListener('scroll', this.handleScroll);
  }

  destroyed(): void {
    window.removeEventListener('scroll', this.handleScroll);
  }

  private handleScroll(): void {
    this.fixed = window.scrollY >= 90;
  }

  private onEmailChanged(email: string): void {
    this.newEmail = email;
    this.emailChanged = true;
  }
}
